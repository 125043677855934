<template>
  <header class="l-header active t-bg-light border-bottom">
    <div class="mobile_menu " :class="{'active':isMobile}">
     <div class="mobile_menu_list">
       <div class="brand">
         <router-link to="/" class="t-link">
           <img
               src="../../assets/img/mm_logo.png"
               alt=""
               class="img-fluid w-100"
           />
         </router-link>
       </div>
       <ul  class="t-list zol-menu">
         <li class="zol-menu__list">
           <router-link to="/aplication" class="t-link zol-menu__link">{{ $t('menu1') }}</router-link>
         </li>
         <li class="zol-menu__list">
           <router-link to="/contact" class="t-link zol-menu__link">{{ $t('menu2') }}</router-link>
         </li>
         <li class="zol-menu__list" v-if="$store.state.me">
           <router-link to="/users/application" class="t-link zol-menu__link">{{ $t('menu3') }}</router-link>
         </li>
         <li class="zol-menu__list" v-if="!$store.state.me">
           <router-link to="/site/login" class="t-link zol-menu__link">{{ $t('login') }}</router-link>
         </li>
         <li class="zol-menu__list" v-if="!$store.state.me">
           <router-link to="/site/signup" class="t-link zol-menu__link">{{ $t('reg') }}</router-link>
         </li>
         <li class="zol-menu__list" v-if="$store.state.me">
           <router-link to="/request" class="t-link zol-menu__link">{{$t('my_aple')}}</router-link>
         </li>
         <li class="zol-menu__list" v-if="!$store.state.me">
           <router-link to="/request" class="t-link zol-menu__link">{{$t('stark')}}</router-link>
         </li>
       </ul>
     </div>

      <nav class="main_navbar">
        <div class="lang">
          <b-dropdown variant="primary">
            <template #button-content>
              {{ isLangName }}
            </template>
            <b-dropdown-item @click="changeLang('oz')">O'zbek</b-dropdown-item>
            <b-dropdown-item @click="changeLang('ru')">Rus</b-dropdown-item>
            <b-dropdown-item @click="changeLang('en')">English</b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-if="$store.state.me">
          <div class="exit_groups">
            <a class="out-btn pull-left mx-4" @click="logOut()">{{$t('exit')}}</a>
          </div>
        </div>
        <div v-else>
          <router-link to="/site/login" class="t-link bttn bttn-sm bttn-round bttn-primary ">{{ $t('login') }}
          </router-link>
          <router-link to="/site/signup" class="t-link bttn bttn-sm bttn-round bttn-gamma">{{ $t('reg') }}
          </router-link>
        </div>
      </nav>
    </div>
    <div class="container  ">
      <div class="row align-items-center">
        <div class="col-7 col-lg-3 col-xl-2">
          <div class="brand">
            <router-link to="/" class="t-link">
              <img
                  src="../../assets/img/mm_logo.png"
                  alt=""
                  class="img-fluid w-100"
              />
            </router-link>
          </div>
        </div>
        <div class="col-5 col-lg-8 col-xl-6 text-right">
          <div class="zol-menu-wrapper">
            <div class="zol-menu-toggle d-inline-block d-lg-none">
                        <span class="zol-menu-open">
                       <button class="btn hamburger_btn" @click="isMobile=!isMobile">
                         <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"><path
                           d="M5.33594 6.66797H18.6699M5.33594 12H18.6699M5.33594 17.332H18.6699" stroke="black"
                           stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                           stroke-linejoin="round"></path></svg></button>
                        </span>
            </div>
            <ul class="t-list zol-menu web_class">
              <li class="zol-menu__list">
                <router-link to="/aplication" class="t-link zol-menu__link">{{ $t('menu1') }}</router-link>
              </li>
              <li class="zol-menu__list">
                <router-link to="/contact" class="t-link zol-menu__link">{{ $t('menu2') }}</router-link>
              </li>
              <li class="zol-menu__list">
                <router-link to="/news" class="t-link zol-menu__link">{{ $t('news') }}</router-link>
              </li>
              <li class="zol-menu__list" v-if="$store.state.me">
                <router-link to="/users/application" class="t-link zol-menu__link">{{ $t('menu3') }}</router-link>
              </li>
              <li class="zol-menu__list" v-if="!$store.state.me">
                <router-link to="/service" class="t-link zol-menu__link">{{ $t('stark') }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 d-xl-block zol-menu web_class">
          <nav class="main_navbar">
            <div class="lang">
              <b-dropdown variant="primary">
                <template #button-content>
                  {{ isLangName }}
                </template>
                <b-dropdown-item @click="changeLang('oz')">O'zbek</b-dropdown-item>
                <b-dropdown-item @click="changeLang('ru')">Rus</b-dropdown-item>
                <b-dropdown-item @click="changeLang('en')">English</b-dropdown-item>
              </b-dropdown>
            </div>
            <a href="#" class="notificationDropBtn" v-if="$store.state.me">
            <span class="iconBtn">
              <b-icon icon="bell" variant="white"></b-icon>
            </span>
              <span class="notificationBadge active">
                <span>0</span>
            </span>
            </a>
            <div v-if="$store.state.me">
              <div class="exit_groups">
                <a class="out-btn pull-left mx-4" @click="logOut()">{{$t('exit')}}</a>
                <div class="pull-left">
                  <div class="profileIconBtnBox">
                    <div>
                      <b-dropdown variant="primary">
                        <template #button-content>
                          {{ $store.state.me.first_name }}
                        </template>
                        <b-dropdown-item>
                          <router-link to="/request">{{$t('my_aple')}}</router-link>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <router-link to="/site/login" class="t-link bttn bttn-sm bttn-round bttn-primary ">{{ $t('login') }}
              </router-link>
              <router-link to="/site/signup" class="t-link bttn bttn-sm bttn-round bttn-gamma">{{ $t('reg') }}
              </router-link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      isLangName: '',
      isMobile:false
    }
  },
  methods: {
    changeLang(item) {
      localStorage.setItem('lang', item);
      window.location.reload()
      this.isLangName = localStorage.getItem('lang')


    },
    logOut() {
      this.$store.state.me = false
      localStorage.removeItem('reg_token')
      this.$router.push('/')
    }
  },
  mounted() {
    this.$store.dispatch('getMe')
    if (!localStorage.getItem('lang')){
      localStorage.setItem('lang','oz')
    }
    this.isLangName = localStorage.getItem('lang')
    axios.get(`users/me/`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('reg_token')}`,
            'Accept-Language':localStorage.getItem('lang')
          }
        }
    )
        .then(res=>{
          axios.get(`users/${res.data.id}/requests/`,
              {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('reg_token')}`,
                  'Accept-Language': localStorage.getItem('lang')
                }
              }
          )
              .then(ress => {

                axios.get(`users/${res.data.id}/requests/${ress.data[0].id}/notifications`,
                    {
                      headers: {
                        'Authorization': `Bearer ${localStorage.getItem('reg_token')}`,
                        'Accept-Language': localStorage.getItem('lang')
                      }
                    }
                )
                    .then(ress => {
                      this.requests = ress.data
                    })
              })
        })


  }
}
</script>
<style>
.mobile_menu{
  position: fixed;
  width: 80%;
  height: 100%;
  background: white;
  z-index: 4;
  left: -200%;
  transition: .4s;
}

@media (max-width: 1200px) {
  .mobile_menu.active{
    left: 0;
    transition: .4s;
  }
  .zol-menu{
    display: inline !important;
    top: 90px !important;
    left: 0 !important;
    width: 100% !important;
  }
  .mobile_menu_list{
    position: relative;
  }
  .web_class{
    display: none !important;
  }
  .main_navbar .t-link{
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .main_navbar .t-link{
    margin-left: 0px;
  }
  .mobile_menu .main_navbar{
    position: absolute;
    bottom: 20px;
    left: 10px;
  }
}
.dropdown-menu.show{
  overflow: hidden;
}
</style>
