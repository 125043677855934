<template>
  <div id="app">
    <div class="content preloader" v-if="isLoading">
      <div id="inTurnFadingTextG">
        <div id="inTurnFadingTextG_1" class="inTurnFadingTextG">M</div>
        <div id="inTurnFadingTextG_2" class="inTurnFadingTextG">A</div>
        <div id="inTurnFadingTextG_3" class="inTurnFadingTextG">D</div>
        <div id="inTurnFadingTextG_4" class="inTurnFadingTextG">A</div>
        <div id="inTurnFadingTextG_5" class="inTurnFadingTextG">N</div>
        <div id="inTurnFadingTextG_6" class="inTurnFadingTextG">I</div>
        <div id="inTurnFadingTextG_1" class="inTurnFadingTextG">Y</div>
        <div id="inTurnFadingTextG_1" class="inTurnFadingTextG"> </div>

        <div id="inTurnFadingTextG_2" class="inTurnFadingTextG">M</div>
        <div id="inTurnFadingTextG_3" class="inTurnFadingTextG">E</div>
        <div id="inTurnFadingTextG_4" class="inTurnFadingTextG">R</div>
        <div id="inTurnFadingTextG_5" class="inTurnFadingTextG">O</div>
        <div id="inTurnFadingTextG_6" class="inTurnFadingTextG">S</div>
        <div id="inTurnFadingTextG_6" class="inTurnFadingTextG"> </div>

        <div id="inTurnFadingTextG_2" class="inTurnFadingTextG">A</div>
        <div id="inTurnFadingTextG_3" class="inTurnFadingTextG">G</div>
        <div id="inTurnFadingTextG_4" class="inTurnFadingTextG">E</div>
        <div id="inTurnFadingTextG_5" class="inTurnFadingTextG">N</div>
        <div id="inTurnFadingTextG_6" class="inTurnFadingTextG">T</div>
        <div id="inTurnFadingTextG_6" class="inTurnFadingTextG">L</div>
        <div id="inTurnFadingTextG_6" class="inTurnFadingTextG">I</div>
        <div id="inTurnFadingTextG_6" class="inTurnFadingTextG">G</div>
        <div id="inTurnFadingTextG_6" class="inTurnFadingTextG">I</div>
      </div>
    </div>
    <router-view/>
  </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
  components: {

  },
  mounted() {
  },
  computed: {
    ...mapState(['isLoading', 'refCount'])
  },
  created() {
    this.$axiosDefault.interceptors.request.use((config) => {
      this.$store.commit('loading', true);
      return config;
    }, (error) => {
      this.$store.commit('loading', false);
      return Promise.reject(error);
    });
    this.$axiosDefault.interceptors.response.use((response) => {
      this.$store.commit('loading', false);
      return response;
    }, (error) => {
      this.$store.commit('loading', false);
      return Promise.reject(error);
    });
  }
}
</script>
<style>
@media (max-width: 1200px) {
  .inTurnFadingTextG{
    font-size: 20px !important;
  }
}
.card{
  min-height: 400px;
}
.dropdown-item.active, .dropdown-item:active a{
  color: #ffffff !important;
}
</style>
