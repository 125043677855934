import kar from './lang/kar.json'
import oz from './lang/oz.json'
import ru from './lang/ru.json'
import Vue from "vue";
import VueI18n from "vue-i18n"
Vue.use(VueI18n);

export default new VueI18n({
    locale:localStorage.getItem('lang') || 'oz',
    messages:{
        en:kar,
        ru:ru,
        oz:oz
    }
})

