<template>
  <div class="home">
    <Navbar></Navbar>
    <div class="hero-4 hero-4">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-xl-7">
            <div class="hero-4__content">
              <h1 class="">
                {{ $t('banner_title') }}
              </h1>
              <p class="t-text-zeta">
                {{ $t('banner_description') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t-pt-120 t-pb-120 t-bg-light-grad">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-3 t-mb-30 mb-lg-0">
            <div class="icon-card d-flex ">
              <div class="icon-card__icon t-mr-16">
                <img src="../assets/img/icons/1.png" alt="" class="img-fluid">
              </div>

              <div class="icon-card__content">
                <h4 class="mt-0 t-text-primary t-mb-10">
                  {{ stats.total }}
                </h4>
                <span class="t-text-zeta d-block text-uppercase sm-text">
                           {{ $t('ser1') }}
                        </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 t-mb-30 mb-lg-0">
            <div class="icon-card d-flex ">
              <div class="icon-card__icon t-mr-16">
                <img src="../assets/img/icons/2.png" alt="" class="img-fluid">
              </div>
              <div class="icon-card__content">
                <h4 class="mt-0 t-text-primary t-mb-10">
                  {{ stats.in_process }}
                </h4>
                <span class="t-text-zeta d-block text-uppercase sm-text">
                           {{ $t('ser2') }}
                        </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 t-mb-30 mb-lg-0">
            <div class="icon-card d-flex ">
              <div class="icon-card__icon t-mr-16">
                <img src="../assets/img/icons/3.png" alt="" class="img-fluid">
              </div>
              <div class="icon-card__content">
                <h4 class="mt-0 t-text-primary t-mb-10">
                  {{ stats.rejected }}
                </h4>
                <span class="t-text-zeta d-block text-uppercase sm-text">
                            {{ $t('ser3') }}
                        </span>

              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="icon-card d-flex ">
              <div class="icon-card__icon t-mr-16">
                <img src="../assets/img/icons/4.png" alt="" class="img-fluid">
              </div>
              <div class="icon-card__content">
                <h4 class="mt-0 t-text-primary t-mb-10">
                  0. so'm
                </h4>
                <span class="t-text-zeta d-block text-uppercase sm-text">
                            {{ $t('ser4') }}
                        </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t-pb-120">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="">
              {{ $t('aks') }}
            </h2>
          </div>
        </div>
        <div class="work-process-wrapper">
          <div class="row h-100">
            <div class="col-md-6 col-lg-3 t-mb-30 mb-lg-0">
              <div class="work-process text-center">
                <div class="work-process-icon work-process-icon--alpha mx-auto">
                  <b-icon icon="image-fill"></b-icon>
                </div>
                <h4 class=" mb-0 t-text-alpha">
                  {{ $t('kat1') }}
                </h4>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 t-mb-30 mb-lg-0 align-self-xl-center">
              <div class="work-process text-center">
                <div class="work-process-icon work-process-icon--primary mx-auto">
                  <b-icon icon="calendar"></b-icon>
                </div>
                <h4 class=" mb-0 t-text-primary">
                  {{ $t('kat2') }}
                </h4>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 t-mb-30 mb-lg-0">
              <div class="work-process text-center">
                <div class="work-process-icon work-process-icon--delta mx-auto">
                  <b-icon icon="file-earmark-binary"></b-icon>
                </div>
                <h4 class="mb-0 t-text-delta">
                  {{ $t('kat3') }}
                </h4>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 t-mb-30 mb-lg-0 align-self-xl-center">
              <div class="work-process work-process--last text-center">
                <div class="work-process-icon work-process-icon--epsilon mx-auto">
                  <b-icon icon="cash-coin"></b-icon>
                </div>
                <h4 class=" mb-0 t-text-epsilon">
                  {{ $t('kat4') }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section data-v-1655b460="" id="privilege" class="relative z-10 bg-white">

      <div data-v-1655b460="" class="container py-12 sm:py-[120px]">
        <div data-v-1655b460="" class="w-full rounded-lg sm:rounded-xl video_contents overflow-hidden" style="height: 728.497px;">
          <h2 data-v-1655b460="" class="text-2xl font-semibold sm:text-5xl sm:leading-tight 2xl:text-[56px] 2xl:leading-[72px] sm:font-medium">
            {{$t('forget')}}
          </h2>
          <iframe width="100%" height="80%" src="https://www.youtube.com/embed/tScyKV58I4o?controls=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </section>
    <div class="t-pb-120  t-bg-light-grad">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="  mb-0">
              {{ $t('news') }}
            </h2>
          </div>
        </div>
        <div class="row t-mt-10">
          <div class="col-md-4" v-for="(itm,ind) in news.results" :key="ind">
            <div class="price-card t-bg-light">
              <div class="t-pl-30 t-pr-30 text-center mx-auto">
                <a href="#" class="t-link">
                  <img src="../assets/img/photo.jpg" alt="" class="img-fluid w-100"/>
                </a>
              </div>
              <div class="t-pl-30 t-pr-30 t-mt-30">
                <a href="#" class="t-link t-link-primary xsm-text">
                  <b-icon icon="calendar2"></b-icon>
                  {{ new Date(itm.published_date).toLocaleDateString() }}
                </a>
                <br>
                <a href="#" class="t-link t-link-primary xsm-text">
                  <b-icon icon="eye"></b-icon>
                  {{ itm.views }}
                </a>
                <h4 class=" t-mt-10">
                  <a href="/news/view?id=3" class="t-link t-link--alpha">
                    {{ itm.title }}
                  </a>
                </h4>

              </div>

              <div class="t-pl-30 t-pr-30 t-mt-30">
                <router-link :to="`/news/view?id=${itm.id}`" class="t-link t-link--alpha">
                        <span class="cards__footer-icon">
                           <b-icon icon="plus-circle"></b-icon>
                        </span>
                  <span class=" font-weight-bold">
                            Batafsil
                        </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="back-to-top">
    <span class="back-top">
        <i class="las la-angle-up"></i>
    </span>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Header/Footer";
import axios from "axios";

export default {
  components: {Footer, Navbar},
  data() {
    return {
      news: [],
      stats: []
    }
  },
  mounted() {
    axios.get(`news`,
        {
          headers: {
            'Accept-Language': localStorage.getItem('lang')
          }
        }
    )
        .then(res => {
          this.news = res.data
        })
    this.$axiosDefault.get('request-statistics/')
        .then(res => {
          this.stats = res.data
        })
  }
}
</script>
<style>
.t-bg-light-grad {
  margin-top: 140px;
  margin-bottom: 100px;
}
.video_contents{
  margin-top: 200px;
}
</style>
