import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import axios from "axios";
export default new Vuex.Store({
  state: {
    isLoading:false,
    refCount:0,
    me:false,
  },
  mutations: {

    loading(state, isLoading) {
      console.log({isLoading})
      if (isLoading) {
        state.refCount++;
        state.isLoading = true;
      } else if (state.refCount > 0) {
        state.refCount--;
        state.isLoading = (state.refCount > 0);
      }
    },
  },
    actions: {
      getMe({state}){
        axios.get(`users/me/`,
            {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('reg_token')}`,
                'Accept-Language':localStorage.getItem('lang')
              }
            }
        )
            .then(res=>{
              state.me=res.data
            })


      },

  },
  modules: {
  }
})
